<template>
  <div class="space-y-4">
    <div
      v-if="tags.length > 0"
      class="mt-2 flex flex-wrap gap-2"
    >
      <div
        v-for="tag in tags"
        :key="tag"
        class="flex h-10 items-center bg-primary-400 pl-2 font-semibold leading-none"
      >
        {{ tag }}

        <button
          class="p-2"
          type="button"
          @click="remove(tag)"
        >
          <UiIcon
            size="tiny"
            name="close"
          />
        </button>
      </div>
    </div>

    <FormKit
      v-model="tagInput"
      :ignore="true"
      type="search"
      :placeholder="context.attrs.placeholder"
      suffix-icon="search"
      @keydown.enter.prevent="add"
    />
  </div>
</template>

<script setup lang="ts">
import type {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const node = computed(() => props.context.node);

const tagInput = ref('');
const tags = ref<string[]>([]);

const mode = ref<'array' | 'string'>(node.value.props.mode ?? 'array');
const rawValue = toRef(props.context, '_value');
const value = computed(() => (rawValue.value ?? (mode.value === 'array' ? [] : '')) as string | string[]);

watch(value, (value) => {
  if (typeof value === 'string') {
    tags.value = value.split('\n').filter(Boolean);
  } else {
    tags.value = value;
  }
}, {immediate: true});

function add() {
  const tagValue = tagInput.value.toLocaleLowerCase();

  if (!tags.value.find(tag => tag.toLocaleLowerCase() === tagValue)) {
    tags.value.push(tagInput.value);
  }

  tagInput.value = '';

  triggerInput();
}

function remove(tag: string) {
  tags.value.splice(tags.value.indexOf(tag), 1);

  triggerInput();
}

function triggerInput() {
  node.value.input(
    mode.value === 'string'
      ? tags.value.join('\n')
      : [...tags.value],
  );
}
</script>
